/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { CircularProgress, Grid } from '@material-ui/core'
import Proptypes from 'prop-types'
import CardBody from '../../components/Card/CardBody'
import CardHeader from '../../components/Card/CardHeader'
import Card from '../../components/Card/Card'
// style cho bảng
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    height: '70vh',
    // padding: 0,
  },
  container2: {
    height: '65vh',
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  buttonfilter: {
    marginRight: '8px',
    backgroundColor: 'red',
    color: '#fff',
  },
  position: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,

    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    zIndex: 10,
  },
  back: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  filterContainer: {
    flexWrap: 'nowrap',
  },
  buttonCreate: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleFilter: {
    color: '#000000',
    marginRight: '8px',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
})

function CustomTable({
  onGetData,
  title,
  description,
  columns,
  keyProps,
  maxCount,
  onClickRow,
  emptyLabel,
  labelRowsPerPage,
}) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [data, setData] = React.useState([])
  const fetchData = async () => {
    try {
      const input = {
        page: page + 1,
        limit: rowsPerPage,
      }

      setLoading(true)
      const newData = await onGetData(input)
      setLoading(false)
      setData(newData)
    } catch (e) {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>{title}</h4>
            {description ? <p className={classes.cardCategoryWhite}>{description}</p> : null}
          </CardHeader>
          <CardBody className={classes.container}>
            {data.length > 0 ? (
              <>
                <TableContainer className={classes.container2}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableBody>
                      {data.map(row => {
                        return (
                          <TableRow
                            onClick={() => onClickRow(row)}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row[keyProps]}
                            style={{
                              backgroundColor: '#fff',
                            }}
                          >
                            {columns.map(column => {
                              const value = row[column.id]
                              return (
                                <TableCell
                                  style={{
                                    wordBreak: 'break-word',
                                    width: column.width,
                                    minWidth: column.minWidth,
                                  }}
                                  key={column.id}
                                  align={column.align}
                                >
                                  {column.format ? column.format(value) : value}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={maxCount || data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={labelRowsPerPage}
                />
              </>
            ) : !loading && data.length === 0 ? (
              <div>
                <p style={{ fontSize: '20px', textAlign: 'center' }}>{emptyLabel}</p>
              </div>
            ) : null}
            {loading ? (
              <div className={data.length > 0 ? classes.back : classes.position}>
                <CircularProgress size={60} />
              </div>
            ) : null}
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

CustomTable.propTypes = {
  onGetData: Proptypes.func,
  columns: Proptypes.array,
  title: Proptypes.string,
  description: Proptypes.any,
  keyProps: Proptypes.string,
  maxCount: Proptypes.number,
  onClickRow: Proptypes.func,
  emptyLabel: Proptypes.any,
  labelRowsPerPage: Proptypes.string,
}

CustomTable.defaultProps = {
  onGetData: () => {
    return new Promise(resolve => {
      resolve([])
    })
  },
  columns: [],
  title: 'No name',
  description: 'Here is a subtitle for this table',
  keyProps: 'id',
  onClickRow: () => {},
}

export default CustomTable
