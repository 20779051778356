/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { useToasts } from 'react-toast-notifications'
// core components
import Navbar from '../components/Navbars/Navbar'
// import Footer from '../components/Footer/Footer'
import Sidebar from '../components/Sidebar/Sidebar'
import TouchNotification from '../components/Touch/TouchNotification'
// import FixedPlugin from '../components/FixedPlugin/FixedPlugin'

import routes from '../routes'

import styles from '../assets/jss/material-dashboard-react/layouts/adminStyle'

import bgImage from '../assets/img/sidebar-2.jpg'
import logo from '../assets/img/capichi_logo.jpg'

let ps

const newDashboardRoute = routes.filter(route => route.invisible !== true)

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />
      }
      return null
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
)

const useStyles = makeStyles(styles)

export default function Admin({ ...rest }) {
  const { addToast, removeAllToasts } = useToasts()
  // styles
  const classes = useStyles()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef()
  // states and functions
  const [image] = React.useState(bgImage)
  const [color] = React.useState('blue')
  // const [fixedClasses, setFixedClasses] = React.useState('dropdown show')
  const [mobileOpen, setMobileOpen] = React.useState(false)
  // const handleImageClick = image => {
  //   setImage(image)
  // }
  // const handleColorClick = color => {
  //   setColor(color)
  // }
  // const handleFixedClick = () => {
  //   if (fixedClasses === 'dropdown') {
  //     setFixedClasses('dropdown show')
  //   } else {
  //     setFixedClasses('dropdown')
  //   }
  // }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const getRoute = () => {
    return window.location.pathname !== '/admin/maps' && window.location.pathname !== '/admin/table'
  }

  // const getRouteShowFixed = () => {
  //   return window.location.pathname !== '/admin/table'
  // }

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', resizeFunction)
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
      window.removeEventListener('resize', resizeFunction)
    }
  }, [mainPanel])
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={newDashboardRoute}
        logoText="Capichi Crm"
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} {...rest} />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* {getRouteShowFixed() ? (
          <FixedPlugin
            handleImageClick={handleImageClick}
            handleColorClick={handleColorClick}
            bgColor={color}
            bgImage={image}
            handleFixedClick={handleFixedClick}
            fixedClasses={fixedClasses}
          />
        ) : null} */}
        <TouchNotification addToast={addToast} removeAllToasts={removeAllToasts} />
      </div>
    </div>
  )
}
