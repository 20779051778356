/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Pie } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

const color = ['#FF6384', '#36A2EB', '#FFCE56', '#4bc0c0', '#2EFE64', '#ECF6CE', '#D8D8D8']

class PieChart extends React.PureComponent {
  // func dựa vào mảng data có sẵn để chuyển sang dataset tương ứng với biểu đồ
  getDataSets = () => {
    const { data, labels } = this.props
    return {
      labels,
      datasets: [
        {
          backgroundColor: color,
          hoverBackgroundColor: color,
          data,
        },
      ],
    }
  }

  renderLabels = () => {
    const { labels } = this.props
    return (
      <Grid item md={5}>
        {labels.map((e, index) => {
          return (
            <div key={e + index} style={{ marginLeft: 12 }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 12 }}>
                <div style={{ width: 12, height: 12, backgroundColor: color[index] }} />
                <span style={{ fontSize: 12, paddingLeft: 12, maxWidth: 60, lineHeight: 1 }}>
                  {e}
                </span>
              </div>
            </div>
          )
        })}
      </Grid>
    )
  }

  render() {
    const { data } = this.props

    return (
      <div>
        <Grid container>
          <Grid item md={7}>
            <Pie
              data={this.getDataSets(data)}
              legend={{
                align: 'start',
                position: 'right',
                fullWidth: false,
                labels: { boxWidth: 12 },
                display: false,
              }}
              height={300}
            />
          </Grid>
          {this.renderLabels()}
        </Grid>
      </div>
    )
  }
}

PieChart.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.array,
}

export default PieChart
