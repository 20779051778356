// func api lấy thông tin thống kê theo giới tính của stamp

import { getMessageErrorFormServer } from '../commons/checkErrorsServer'
import request from './request'
import {
  stamp_gender,
  stamp_age,
  coupon_gender,
  coupon_age,
  number_of_visit_stamp,
  stamp_users_count,
  coupon_users_count,
} from './config'

/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getUsedStampHistory = async ({ authen }) => {
  try {
    const result = await request(authen).get(`${stamp_users_count}`)
    return {
      success: true,
      data: result.data?.data || [],
      today: result.data?.today || undefined,
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getGenderStamp = async ({ authen }) => {
  try {
    const result = await request(authen).get(`${stamp_gender}`)
    return {
      success: true,
      data: result.data?.data || [],
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

// func api lấy thông tin thống kê theo độ tuổi của stamp
/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getAgeStamp = async ({ authen }) => {
  try {
    const result = await request(authen).get(`${stamp_age}`)
    return {
      success: true,
      data: result.data?.data || [],
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

// func api lấy thông tin thống kê theo số lượng sử dụng của stamp
/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getNumberOfVisits = async ({ authen }) => {
  try {
    const result = await request(authen).get(`${number_of_visit_stamp}`)
    return {
      success: true,
      data: result.data?.data || [],
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getUsedCouponHistory = async ({ authen }) => {
  try {
    const result = await request(authen).get(`${coupon_users_count}`)
    return {
      success: true,
      data: result.data?.data || [],
      today: result.data?.today,
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

// func api lấy thông tin thống kê theo giới tính của coupon
/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getGenderCoupon = async ({ authen }) => {
  try {
    const result = await request(authen).get(`${coupon_gender}`)
    return {
      success: true,
      data: result.data?.data || [],
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

// func api lấy thông tin thống kê theo độ tuổi của coupon
/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getAgeCoupon = async ({ authen }) => {
  try {
    const result = await request(authen).get(`${coupon_age}`)
    return {
      success: true,
      data: result.data?.data || [],
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}
