import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Checkbox } from '@material-ui/core'

// component sử dụng cho việc lựa chọn các tiêu chí
class SelectBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [{ id: -1, name: 'options.all', check: true }].concat(props.defaultData || []),
    }
  }

  // @get
  getData = () => {
    const { data } = this.state
    return data
  }

  // @get
  getLanguage = () => {
    const { language } = this.props
    return language
  }

  // @get
  getTitle = () => {
    const { title } = this.props
    return title || ''
  }

  // func khi click vào 1 ô check box
  onChangeItem = (event, e) => {
    const value = event.nativeEvent.target.checked

    const { data } = this.state
    // kiểm tra xem ô tích có phải là all ko
    const isAll = e.id === -1

    // kiểm tra xem ô All trước đó đã được tích chưa
    const oldAllChecked = data[0].check

    // biến check tất cả xem tất cả các item của mảng có được checked hay không
    const checkAllChecked = data.filter(item => item.check).length === data.length

    const newData = data.map(item => {
      /**
       * nếu "All" thay đổi sẽ chỉ update trạng thái của all
       */
      if (isAll) {
        if (item.id === -1) return { ...item, check: value }

        // nếu tât cả trước đó đều được checked, bỏ check toàn bộ
        if (checkAllChecked) return { ...item, check: false }

        return item
      }

      // nếu ô all đã được tích, 1 ô khác thay đổi
      if (oldAllChecked) {
        // đưa all => uncheck
        if (item.id === -1) return { ...item, check: false }
        // ô chọn theo value
        if (item.id === e.id) return { ...item, check: value }
        return { ...item, check: true }
      }

      // trường hợp ngoại lệ, sẽ chỉ set ô được tích
      if (item.id === e.id) return { ...item, check: value }

      return item
    })
    // kiểm tra xem tất cả cac value đề check => sẽ check thêm ô all
    const checkAll = newData.reduce((a, b) => {
      if (b.id !== -1) return a || !b.check
      return false
    }, false)

    if (!checkAll) newData[0].check = true

    this.setState({ data: newData })
  }

  renderItem = e => {
    const { data } = this.state
    const { language } = this.props
    const all = data[0]
    return (
      <div key={e.id} style={{ width: 160 }}>
        <Grid container style={{ alignItems: 'center' }}>
          <Checkbox
            onChange={event => this.onChangeItem(event, e)}
            checked={all.check || !!e.check}
          />
          <span>{language.t(e.name)}</span>
        </Grid>
      </div>
    )
  }

  renderTitle = () => {
    return <p>{this.getTitle()}</p>
  }

  renderContainer = () => {
    const { data } = this.state
    return (
      <>
        {this.renderTitle()}
        <Grid container>{data.map(e => this.renderItem(e))}</Grid>
      </>
    )
  }

  render() {
    return this.renderContainer()
  }
}

SelectBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  language: PropTypes.any,
  defaultData: PropTypes.array,
}

export default SelectBox
