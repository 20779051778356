import React from 'react'
import moment from 'moment'
import Axios from 'axios'
import localization from 'moment/locale/vi'
// import { useSelector } from 'react-redux'
import { getHistoryStampCard, getListUserApi } from '../../../api'
import CustomTable from '../../../components/Table/TableCustom'
import { AUTHEN_TOKEN } from '../../../constants/define'
import { formatMoney } from '../../../commons'

const defineValue = {
  sku: {
    key: 'sku',
    displayName: 'stampList.id',
    object: 'sku',
    default: '',
  },
  namePlace: {
    key: 'namePlace',
    displayName: 'storeName',
    object: 'namePlace',
    default: '',
  },
  detail: {
    key: 'detail',
    displayName: 'stampHistory.discount',
    object: 'detail',
    default: '',
  },
  typeStamp: {
    key: 'typeStamp',
    displayName: 'stampList.type',
    object: 'typeStamp',
    default: '',
  },
  typeAction: {
    key: 'typeAction',
    displayName: 'action',
    object: 'typeAction',
    default: '',
  },
  user: {
    key: 'user',
    displayName: 'customer',
    object: 'user',
    default: {
      id: 0,
      username: '',
    },
  },
  date: {
    key: 'date',
    displayName: 'time',
    object: 'date',
    default: '',
  },
  edit: {
    key: 'edit',
    displayName: 'edit',
    object: 'edit',
    default: '',
    typeColumn: 'action',
  },
  quantity: {
    key: 'quantity',
    displayName: 'quantity',
    object: 'quantity',
    default: '',
  },
  money: {
    key: 'money',
    displayName: 'money',
    object: 'money',
    default: '',
  },
}

const columnsStamp = [
  { id: defineValue.sku.key, label: defineValue.sku.displayName, minWidth: 40 },
  { id: defineValue.namePlace.key, label: defineValue.namePlace.displayName, minWidth: 40 },
  { id: defineValue.quantity.key, label: defineValue.quantity.displayName, minWidth: 40 },
  {
    id: defineValue.money.key,
    label: defineValue.money.displayName,
    minWidth: 40,
    format: value => `${formatMoney(value)} VNĐ`,
  },
  { id: defineValue.typeStamp.key, label: defineValue.typeStamp.displayName, minWidth: 40 },
  {
    id: defineValue.typeAction.key,
    label: defineValue.typeAction.displayName,
    minWidth: 40,
    typeColumn: 'translate',
  },
  {
    id: defineValue.user.key,
    label: defineValue.user.displayName,
    minWidth: 40,
    align: 'left',
    format: value => value.toLocaleString(),
    type: defineValue.user.object,
  },
  {
    id: defineValue.date.key,
    label: defineValue.date.displayName,
    minWidth: 75,
    align: 'right',
    format: value =>
      moment(value)
        .locale('vi', localization)
        .format('DD-MM-YYYY HH:mm'),
  },
  {
    id: defineValue.edit.key,
    label: defineValue.edit.displayName,
    minWidth: 40,
    align: 'center',
    typeColumn: defineValue.edit.typeColumn,
    type: defineValue.edit.object,
  },
]

const listFilter = [
  { value: 'all', label: 'options.all' },
  { value: 'added', label: 'options.collect' },
  { value: 'used', label: 'options.used' },
]

let cancelToken = Axios.CancelToken.source()
const getNewCancelToken = () => {
  return Axios.CancelToken.source()
}

export default function CouponTable() {
  const [count, setCount] = React.useState(0)
  // const language = useSelector(state => state.languageReducer.language)

  const formatDataTable = data => {
    return data.map(item => ({
      ...item,
      [defineValue.sku.key]: `sku-${item.id}`,
      [defineValue.namePlace.key]: item.place.name,
      [defineValue.detail.key]: item.action === 'added' ? '' : item.detail,
      [defineValue.typeAction.key]: item.action === 'used' ? 'options.used' : 'options.collect',
      [defineValue.typeStamp.key]: item.type,
      [defineValue.user.key]: item.user.username,
      [defineValue.date.key]: item.date,
      [defineValue.edit.key]: 'view',
      key: Math.random(),
      disabled: item.action !== 'added',
      [defineValue.quantity.key]: item.quantity,
      [defineValue.money.key]: item.money,
    }))
  }
  const onGetData = async ({ page, rowsPerPage, filter, keyword = '' }) => {
    if (cancelToken) cancelToken.cancel('a')
    cancelToken = undefined
    cancelToken = getNewCancelToken()
    const authen = localStorage.getItem(AUTHEN_TOKEN)
    const result = await getHistoryStampCard({
      authen,
      page,
      limit: rowsPerPage,
      cancel: cancelToken,
      filter,
      keyword,
    })
    const { isSuccess, message, data, maxCount } = result
    if (!isSuccess) {
      if (message === 'a') return []
      return []
    }
    const newdata = await formatDataTable(data)
    setCount(maxCount)
    return newdata
  }

  const filterKeywordArray = [
    {
      function: getListUserApi,
      key: 'username',
      label: 'customer',
      emptyText: 'stampHistory.emptyAutocomplete',
    },
  ]

  return (
    <CustomTable
      keyProps="key"
      maxCount={count}
      onGetData={onGetData}
      title="stampList.label"
      description="stampHistory.description"
      columns={columnsStamp}
      filter={listFilter}
      emptyLabel="stampHistory.emptyLabel"
      filterKeyword={filterKeywordArray}
    />
  )
}
