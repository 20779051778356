/* eslint-disable no-undef */
import request from './request'
import { profileApi, getListUser, user_stores } from './config'
import { getMessageErrorFormServer } from '../commons'

export const getProfileApi = async authenToken => {
  try {
    const { data } = await request(authenToken).get(`${profileApi}`)
    // console.log({ data })
    return {
      status: 'success',
      data: data.data,
    }
  } catch (error) {
    return {
      status: 'failed',
      message: getMessageErrorFormServer(error),
    }
  }
}

export const getListUserApi = async (authenToken, keyword = '', page = 0) => {
  try {
    const { data } = await request(authenToken).get(
      `${getListUser}?keyword=${keyword}&page=${page}&all=${!page}`
    )
    // console.log({ data })
    return {
      status: 'success',
      data: data.data,
    }
  } catch (error) {
    return {
      status: 'failed',
      message: getMessageErrorFormServer(error),
    }
  }
}

/**
 *
 * update thong tin user cho nha hang
 * @param {} gender
 * @param {} age
 * @param {} note
 */
export const updateUserStore = async ({ authen, gender, age, note, id }) => {
  try {
    const formData = new FormData()
    formData.append('user_store[gender]', gender)
    formData.append('user_store[age]', age)
    formData.append('user_store[note]', note)
    await request(authen).put(`${user_stores}/${id}`, formData)
    return {
      success: true,
      // data: ,
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}
