import { connect } from 'react-redux'
import LineModule from '../Modules/Line'
import { AUTHEN_TOKEN } from '../../../constants/define'
import { getUsedCouponHistory } from '../../../api/analytic'

class LineUsedCoupon extends LineModule {
  getAuthenToken = () => {
    return localStorage.getItem(AUTHEN_TOKEN)
  }

  callApi = (...arg) => {
    return getUsedCouponHistory(...arg)
  }

  getTitle = () => {
    const language = this.getLanguage()
    return language.t('dashboard.used_coupon')
  }

  getLanguage = () => {
    const { language } = this.props
    return language
  }
}

const mapState = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapState)(LineUsedCoupon)
