/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import { connect } from 'react-redux'
import styles from '../../assets/jss/material-dashboard-react/components/languageStyle'
import vnFlag from '../../assets/img/vn-flag.png'
import jpFlag from '../../assets/img/jp-flag.png'
import koFlag from '../../assets/img/ko-flag.png'
import enFlag from '../../assets/img/en-flag.png'

import { languageKey } from '../../constants/define'

class Index extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      currentLocale: 'vi',
    }
  }

  onResetState = () => {
    this.setState({
      anchorEl: null,
      currentLocale: 'vi',
    })
  }

  openPopup = event => {
    event.preventDefault()
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  closePopup = () => {
    this.setState({
      anchorEl: null,
    })
  }

  onSelectLanguage = (event, locale) => {
    const { onChangeLanguage } = this.props
    event.preventDefault()
    if (onChangeLanguage) onChangeLanguage(locale)
    this.setState({
      currentLocale: locale,
      anchorEl: null,
    })
  }

  getFlag = () => {
    const { currentLocale } = this.state
    if (currentLocale === languageKey.ja) return jpFlag
    if (currentLocale === languageKey.ko) return koFlag
    if (currentLocale === languageKey.en) return enFlag
    return vnFlag
  }

  render() {
    const { anchorEl } = this.state
    const { classes, customClass } = this.props
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const { language } = this.props
    return (
      <div className={customClass}>
        <a
          href="#"
          style={{ height: '28px' }}
          className={classes.viewFlag}
          onClick={this.openPopup}
        >
          <img src={this.getFlag()} className={classes.imgFlag} />
        </a>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          style={{ marginTop: '10px' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div id="popup-view" className={classes.popupView}>
            <a
              href="#"
              className="language-item"
              onClick={e => this.onSelectLanguage(e, languageKey.vi)}
            >
              <img src={vnFlag} className={classes.imgFlagInput} />
              <span>{language.t('language.vi')}</span>
            </a>
            <a
              href="#"
              className="language-item"
              onClick={e => this.onSelectLanguage(e, languageKey.ja)}
            >
              <img src={jpFlag} className={classes.imgFlagInput} />
              <span>{language.t('language.ja')}</span>
            </a>
            <a
              href="#"
              className="language-item"
              onClick={e => this.onSelectLanguage(e, languageKey.ko)}
            >
              <img src={koFlag} className={classes.imgFlagInput} />
              <span>{language.t('language.ko')}</span>
            </a>
            <a
              href="#"
              className="language-item"
              onClick={e => this.onSelectLanguage(e, languageKey.en)}
            >
              <img src={enFlag} className={classes.imgFlagInput} />
              <span>{language.t('language.en')}</span>
            </a>
          </div>
        </Popover>
      </div>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.any,
  customClass: PropTypes.any,
  onChangeLanguage: PropTypes.func,
  language: PropTypes.any,
}

export default connect(state => ({ language: state.languageReducer.language }), null, null, {
  forwardRef: true,
})(withStyles(styles)(Index))
