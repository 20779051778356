/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import { connect } from 'react-redux'
import styles from '../../assets/jss/material-dashboard-react/components/languageStyle'
import vnFlag from '../../assets/img/vn-flag.png'
import jpFlag from '../../assets/img/jp-flag.png'
import koFlag from '../../assets/img/ko-flag.png'
import enFlag from '../../assets/img/en-flag.png'
import { setLanguage } from '../../redux/language/actions'

class Index extends PureComponent {
  constructor(props) {
    super(props)
    const locale = localStorage.getItem('LOCALE')
    this.state = {
      anchorEl: null,
      currentLocale: locale,
    }
  }

  openPopup = event => {
    event.preventDefault()
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  closePopup = () => {
    this.setState({
      anchorEl: null,
    })
  }

  onSelectLanguage = (event, locale) => {
    const { onChangeLanguage } = this.props
    event.preventDefault()
    // setI18nConfig(locale)
    if (onChangeLanguage) onChangeLanguage(locale)
    this.setState({
      currentLocale: locale,
      anchorEl: null,
    })
  }

  getFlag = () => {
    const { currentLocale } = this.state
    if (currentLocale === 'ja') return jpFlag
    if (currentLocale === 'ko') return koFlag
    if (currentLocale === 'en') return enFlag
    return vnFlag
  }

  render() {
    const { anchorEl } = this.state
    const { classes, customClass } = this.props
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    return (
      <div className={customClass}>
        <a href="#" className={classes.viewFlag} onClick={this.openPopup}>
          <img src={this.getFlag()} className={classes.imgFlag} />
        </a>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          style={{ marginTop: '10px' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div id="popup-view" className={classes.popupView}>
            <a href="#" className="language-item" onClick={e => this.onSelectLanguage(e, 'ja')}>
              <img src={jpFlag} className={classes.imgFlag} />
              <span>日本語</span>
            </a>
            <a href="#" className="language-item" onClick={e => this.onSelectLanguage(e, 'vi')}>
              <img src={vnFlag} className={classes.imgFlag} />
              <span>Tiếng Việt</span>
            </a>
            <a href="#" className="language-item" onClick={e => this.onSelectLanguage(e, 'ko')}>
              <img src={koFlag} className={classes.imgFlag} />
              <span>한국</span>
            </a>
            <a href="#" className="language-item" onClick={e => this.onSelectLanguage(e, 'en')}>
              <img src={enFlag} className={classes.imgFlag} />
              <span>English</span>
            </a>
          </div>
        </Popover>
      </div>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.any,
  customClass: PropTypes.any,
  onChangeLanguage: PropTypes.func,
}

const mapDispatchToProps = dispatch => {
  return {
    onChangeLanguage: locale => dispatch(setLanguage(locale)),
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Index))
