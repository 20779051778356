import emojione from 'emojione'

// emojione.unicodeAlt = false
// cho phep chuyen doi tu ma ASC II
emojione.ascii = true
// func: chuyển đổi text chứa emoji thành text có chứa short name của emoji
export const emojiToString = text => {
  // const string = emojione.shortnameToAscii(emojione.toShort(text || ''))
  const string = emojione.toShort(text || '')

  return string
}
// func: chuyển đổi text thành text có chứa emoji
export const stringToEmoji = text => {
  const emoji = emojione.shortnameToUnicode(text || '')
  return emoji
}
