import config from '../config_env'

/*
 * api domain constants
 */
// /** Server Dev */
export const baseURL = config.baseUrl
export const version = 'api/v101/'

export const TIMEOUT = 30000

/** ************** API USER  ************** */
export const loginApi = 'restaurants/sign_in'
export const signOutApi = 'restaurants/sign_out'

/** ============== API COUPON ============= */
export const listCouponApi = `${version}restaurants/coupons`
export const historyCouponApi = `${version}restaurants/coupons_history`

/** ============== API STAMPCARD ============= */
export const listStampcardApi = `${version}restaurants/stamps`
export const historyStampApi = `${version}restaurants/stamps_history`
export const detailHistoryStampApi = `${version}restaurants/stamp_added_histories`
export const historyEditedStampApi = `${version}restaurants/stamp_added_histories/`

/** ============== API PROFILE ============= */
export const profileApi = `${version}restaurants/profile`
export const getListUser = `${version}restaurants/search_users`

// analytic
export const getStats = `${version}restaurants/stats`
export const stamp_gender = `${getStats}/stamp_gender`
export const stamp_age = `${getStats}/stamp_age`
export const coupon_gender = `${getStats}/coupon_gender`
export const coupon_age = `${getStats}/coupon_age`
export const number_of_visit_stamp = `${getStats}/number_of_visit_stamp`
export const stamp_users_count = `${getStats}/stamp_users_count`
export const coupon_users_count = `${getStats}/coupon_users_count`
export const visit_history = id => `${getStats}/${id}/visit_history`

export const user_stores = `${version}restaurants/user_stores`

/** ============== API NOTIFICATION ============= */
export const push_notification = `${version}restaurants/announcements`
