import validator from 'validator'

export const validatePassword = value => {
  if ((value.length < 8 || value.length > 25) && value !== '') {
    return 'validatePassword'
  }
  return ''
}

export const validateEmail = value => {
  if (!validator.isEmail(value) && value !== '') {
    return 'validateEmail'
  }
  return ''
}
