/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import Table from './Table'
import { getMemberList } from '../../api/member'
import { AUTHEN_TOKEN, formatTime } from '../../constants/define'
import { getLocale, getLocalization, formatMoney } from '../../commons'
import calVisit from '../../commons/visit'
import '../../assets/css/text.css'
import CreateButton from '../../components/CustomButtons/CreateButton'

const formatData = ({ id, user, history, summary, total }) => {
  return {
    id,
    user,
    history,
    summary,
    total,
  }
}

class MemberList extends React.Component {
  // cấu trúc dữ liệu cho bảng
  columns = [
    {
      id: 'id',
      label: 'sku',
      width: 30,
      align: 'center',
    },
    {
      id: 'user',
      align: 'left',
      label: 'User',
      format: value => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ width: '80px', height: '80px', borderRadius: 10 }}
            src={value.avatar}
            alt=""
          />
          <div style={{ marginLeft: '15px' }}>
            <p
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                marginTop: 0,
              }}
              className="txt"
            >
              {value.username}
            </p>
            <p
              style={{ fontSize: 14, color: '#43425D', marginTop: '-12px', opacity: 0.5 }}
            >{`${this.getLanguage().t('member.gender')}: ${
              value.gender ? this.getLanguage().t(`${value.gender}`.toLowerCase()) : ''
            }`}</p>
            <p
              style={{
                fontSize: 14,
                color: '#43425D',
                marginBottom: 0,
                marginTop: '-12px',
                opacity: 0.5,
              }}
            >{`${this.getLanguage().t('member.age')}: ${value.age || ''}`}</p>
          </div>
        </div>
      ),
      width: '200px',
    },
    {
      id: 'history',
      label: 'history',
      format: value => {
        if (!Array.isArray(value)) return null

        return (
          <div style={{ display: 'flex' }}>
            {value.slice(0, 7).map((element, index) => {
              return (
                <div key={index} style={{ marginRight: '15px', width: `${100 / 7}%` }}>
                  <p style={{ textAlign: 'center' }}>
                    {moment(element.created_at)
                      .locale(getLocale(), getLocalization())
                      .format(formatTime.date)}
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    {element.money
                      ? `${formatMoney(Number(element.money || 0))} ${this.getLanguage().t(
                          'currency'
                        )}`
                      : '-'}
                  </p>
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      id: 'summary',
      label: 'summary',
      format: value => {
        const { time, day } = calVisit(value.visit_frequency)
        return (
          <>
            <div style={{ alignItems: 'center' }}>
              <p style={{ textAlign: 'center' }}>{this.getLanguage().t('member.average_paying')}</p>
              <p style={{ textAlign: 'center' }}>
                {formatMoney(Number(value.average_paying || 0))} {this.getLanguage().t('currency')}
              </p>
            </div>
            <div style={{ alignItems: 'center' }}>
              <p style={{ textAlign: 'center' }}>
                {this.getLanguage().t('member.visit_frequency')}
              </p>
              <p style={{ textAlign: 'center' }}>
                {this.getLanguage().t(day <= 1 ? 'member.time_day' : 'member.day_time', {
                  time,
                  day,
                })}
              </p>
            </div>
          </>
        )
      },
      width: 90,
    },
    {
      id: 'total',
      label: 'Total stamp',
      format: value => (
        <div style={{ alignItems: 'center' }}>
          <p style={{ textAlign: 'center' }}>{this.getLanguage().t('member._total')}</p>
          <p style={{ textAlign: 'center' }}>{this.getLanguage().t('member._point')}</p>
          <p style={{ textAlign: 'center' }}>{value}</p>
        </div>
      ),
      width: 60,
    },
  ]

  constructor(props) {
    super(props)
    this.state = {
      error: 'member.noData',
      maxCount: 0,
    }
  }

  getAuthenToken = () => {
    return localStorage.getItem(AUTHEN_TOKEN)
  }

  getLanguage = () => {
    const { language } = this.props
    return language
  }

  // func goi api lấy danh sách member
  onGetData = ({ page, limit }) => {
    return new Promise(resolve => {
      getMemberList({ authen: this.getAuthenToken(), page, limit }).then(r => {
        if (r.success) {
          const newData = r.data.map(i =>
            formatData({
              id: i.user?.id,
              user: i.user,
              history: i.stamp_added_histories,
              summary: i.summary,
              total: i.summary?.total_stamps,
            })
          )
          const newState = {
            maxCount: r.count || 0,
          }
          if (newData.length <= 0) newState.error = 'member.noData'

          this.setState(newState)
          resolve(newData)
          return
        }
        this.setState({ error: 'somethingWrong' })
        resolve([])
      })
    })
  }

  // func khi click vaof 1 row
  onClickRow = data => {
    const { history } = this.props
    history.push(`/admin/memberlist/${data.id}`)
  }

  // @get lấy ra title của btn
  getTitleCreate = () => {
    return 'Push notification'
  }

  // func khi click vào btn push notificaiton => di chuyển đến màn hình tạo notificaiton
  onClickButtonPush = () => {
    const { history } = this.props
    history.push(`/admin/pushnotification`)
  }

  // func render trang thai loading
  renderLoading = () => {}

  // func render trang thai error
  renderError = () => {}

  // render btn create
  renderCreate = () => {
    return (
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div />
        <Grid item md={2} xs={5}>
          <CreateButton title={this.getTitleCreate()} onClick={this.onClickButtonPush} />
        </Grid>
      </Grid>
    )
  }

  render() {
    const language = this.getLanguage()
    const { error, maxCount } = this.state
    return (
      <>
        {this.renderCreate()}
        <Table
          keyProps="id"
          onClickRow={this.onClickRow}
          title={language.t('member.title')}
          description={
            maxCount > 0 ? (
              <span>
                {language.t('member.description_')}
                <span style={{ fontSize: 22, fontWeight: 'bold' }}>{maxCount}</span>
                {language.t('member._description')}
              </span>
            ) : (
              ''
            )
          }
          maxCount={maxCount}
          columns={this.columns}
          onGetData={this.onGetData}
          emptyLabel={language.t(error)}
          labelRowsPerPage={language.t('rowPerPage')}
        />
      </>
    )
  }
}

MemberList.propTypes = {
  history: PropTypes.any,
  language: PropTypes.any,
}
const mapState = state => {
  return {
    language: state.languageReducer.language,
  }
}
export default connect(mapState)(MemberList)
