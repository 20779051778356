import React from 'react'
import { Grid, Card, CircularProgress, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import moment from 'moment'
import { Alert } from '@material-ui/lab'
import TableHistory from './TableHistory'
import { getMemberDetail, getMemberHistory } from '../../api/member'
import { AUTHEN_TOKEN, formatTime } from '../../constants/define'
import { getLocale, getLocalization, formatMoney, stringToEmoji } from '../../commons'
import calVisit from '../../commons/visit'
import '../../assets/css/text.css'
import PopupEdit from './PopupEdit'

const styles = {
  txtCenter: {
    textAlign: 'center',
  },
  textCenterBold: { textAlign: 'center', fontWeight: '500' },
  gridCenter: { padding: '0px 12px 0px 12px', alignItems: 'center' },
}

class DetailMember extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      summary: {},
      loading: true,
      error: '',
      limit: 10,
      history: [],
      page: 0,
      count: 0,
      loadingHistory: false,
      alertSuccessUpdate: false,
    }

    this.columns = [
      {
        id: 'id',
        label: 'member.number_stt',
        width: 30,
        align: 'center',
        format: (value, row, index) => index + 1,
      },
      {
        id: 'time',
        label: 'member.time',
        width: 40,
        align: 'center',
        format: (value, row) =>
          `${moment(row.created_at)
            .locale(getLocale(), getLocalization())
            .format(formatTime.time)}`,
      },
      {
        id: 'date',
        label: 'member.date',
        width: 40,
        align: 'center',
        format: (value, row) =>
          `${moment(row.created_at)
            .locale(getLocale(), getLocalization())
            .format(formatTime.date)}`,
      },
      {
        id: 'paying',
        label: 'member.paying',
        width: 40,
        align: 'center',
        format: value =>
          value ? `${formatMoney(Number(value))} ${this.getLanguage().t('currency')}` : '-',
      },
      {
        id: 'point',
        label: 'member.point',
        width: 30,
        align: 'center',
      },
      // {
      //   id: 'action',
      //   label: 'Action',
      //   width: 30,
      //   align: 'center',
      // },
    ]

    this.refEdit = React.createRef()
  }

  componentDidMount() {
    this.onGetData()
  }

  getAuthenToken = () => {
    return localStorage.getItem(AUTHEN_TOKEN)
  }

  getLanguage = () => {
    const { language } = this.props
    return language
  }

  getUserId = () => {
    const { match } = this.props
    const { id } = match.params || {}
    return id
  }

  // func goi api để lấy dữ liệu
  // dùng promise all để gọi các func con
  onGetData = async () => {
    Promise.all([await this.onGetInfoUser(), await this.onGetHistory()]).then(
      ([dataUser, dataHistory]) => {
        if (!dataUser.success) {
          this.setState({ loading: false, error: 'somethingWrong' })
          return
        }
        this.setState({
          user: dataUser.user,
          summary: dataUser.summary,
          history: dataHistory.data || [],
          page: dataHistory.page,
          count: dataHistory.count,
          loading: false,
        })
      }
    )
  }

  // func goi api để lấy thông tin của user
  // @return promise
  onGetInfoUser = () => {
    return new Promise(resolve => {
      getMemberDetail({ authen: this.getAuthenToken(), id: this.getUserId() }).then(r => {
        if (r.success) {
          resolve({ ...r.data, success: true })
        } else {
          resolve({ success: false })
        }
      })
    })
  }

  // func goi api để lấy danh sách lịch sử stamp
  // @reture promise
  onGetHistory = () => {
    return new Promise(resolve => {
      const { page, limit } = this.state
      getMemberHistory({
        authen: this.getAuthenToken(),
        id: this.getUserId(),
        page: page + 1,
        limit,
      }).then(r => {
        if (r.success) {
          resolve({ data: r.data, success: true, page: r.page?.current || 1, count: r.count })
        } else {
          resolve({ success: false })
        }
      })
    })
  }

  // func gọi lại api sau khi thay đổi page hoặc rows per page
  // setstate luôn data thay vì trả về promise
  onGetHistoryChangePage = () => {
    const { page, limit } = this.state
    this.setState({ loadingHistory: true }, () => {
      getMemberHistory({
        authen: this.getAuthenToken(),
        id: this.getUserId(),
        page,
        limit,
      }).then(r => {
        if (r.success) {
          this.setState({
            history: r.data,
            page: r.page?.current || 1,
            count: r.count,
            loadingHistory: false,
          })
        } else {
          this.setState({ loadingHistory: false })
        }
      })
    })
  }

  onChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => {
      this.onGetHistoryChangePage()
    })
  }

  onChangeRowsPerPage = event => {
    this.setState({ page: 1, limit: +event.target.value }, () => {
      this.onGetHistoryChangePage()
    })
  }

  // xoá alert thông báo
  // mở popup cập nhật thông tin
  onShowPopup = () => {
    if (this.timeoutUpdate) clearTimeout(this.timeoutUpdate)
    this.setState({ alertSuccessUpdate: false })
    if (this.refEdit.current) {
      const { user } = this.state
      this.refEdit.current.show({
        gender: user.gender,
        age: user.age,
        note: stringToEmoji(user.note),
        id: this.getUserId(),
      })
    }
  }

  // func callback sau khi cập nhật thông tin user thành công
  onSaveData = ({ gender, age, note }) => {
    if (this.timeoutUpdate) clearTimeout(this.timeoutUpdate)
    this.setState(
      state => ({
        user: { ...state.user, gender, age, note },
        alertSuccessUpdate: true,
      }),
      () => {
        this.timeoutUpdate = setTimeout(() => {
          this.setState({ alertSuccessUpdate: false })
        }, 5000)
      }
    )
  }

  // func hiển thị popup chỉnh sửa thông tin user
  onEditUser = () => {
    this.onShowPopup()
  }

  renderRow = ({ key, value }) => {
    return (
      <Grid container>
        <Grid item md={2}>
          <p style={{ margin: 0, fontWeight: '500' }}>{key}:</p>
        </Grid>
        <Grid item md={10}>
          <p style={{ margin: 0 }}>{value}</p>
        </Grid>
      </Grid>
    )
  }

  renderAlert = () => {
    const { alertSuccessUpdate } = this.state

    if (!alertSuccessUpdate) return null

    return <Alert style={{ marginBottom: '15px' }}>{this.getLanguage().t('updateSuccess')}</Alert>
  }

  // func render phân thông tin người dùng
  // @return reactnode
  renderInfo = () => {
    const { user } = this.state
    const language = this.getLanguage()
    return (
      <Card style={{ padding: 12 }}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid
            item
            md={2}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
          >
            <img alt="" style={{ width: 80, height: 80, borderRadius: 10 }} src={user.avatar} />
          </Grid>
          <Grid item md={2} style={{ paddingRight: 12 }}>
            <p style={{ textAlign: 'left' }}>{user.username}</p>
          </Grid>
          <Grid item md={6}>
            {this.renderRow({
              key: language.t('member.gender'),
              value: user.gender ? this.getLanguage().t(`${user.gender}`.toLowerCase()) : '',
            })}
            {this.renderRow({ key: language.t('member.age'), value: user.age || '' })}
            {this.renderRow({ key: language.t('member.note'), value: stringToEmoji(user.note) })}
          </Grid>
          <Grid item md={2} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#F7941D' }}
              onClick={this.onEditUser}
            >
              {language.t('edit')}
            </Button>
          </Grid>
        </Grid>
      </Card>
    )
  }

  // func render phần hiển thị thông tin sử dụng coupon của người dùng
  // @return reactnode | null
  renderInfoStamp = () => {
    const { summary } = this.state
    const language = this.getLanguage()

    // let time = 0
    // let day = 1
    // if (summary.visit_frequency > 1) day = Math.floor(summary.visit_frequency)
    // else if (summary.visit_frequency !== 0) {
    //   time = Math.floor(1 / summary.visit_frequency)

    // }
    const { time, day } = calVisit(summary.visit_frequency)
    return (
      <Grid container>
        <Grid item md={2}>
          <p style={styles.textCenterBold}>{language.t('member.visiting_history')}</p>
        </Grid>
        <Grid item md={2} style={styles.gridCenter}>
          <p style={styles.textCenterBold}>{language.t('member.average_paying')}</p>
          <p style={styles.txtCenter}>
            {formatMoney(Number(summary.average_paying || 0))} {this.getLanguage().t('currency')}
          </p>
        </Grid>
        <Grid item md={2} style={styles.gridCenter}>
          <p style={styles.textCenterBold}>{language.t('member.visit_frequency')}</p>
          <p style={styles.txtCenter}>
            {this.getLanguage().t(day <= 1 ? 'member.time_day' : 'member.day_time', {
              time,
              day,
            })}
          </p>
        </Grid>
        <Grid item md={2} style={styles.gridCenter}>
          <p style={styles.textCenterBold}>{language.t('member.total_point')}</p>
          <p style={styles.txtCenter}>{summary.total_stamps}</p>
        </Grid>
        <Grid item md={2} style={styles.gridCenter}>
          <p style={styles.textCenterBold}>{language.t('member.total_visit')}</p>
          <p style={styles.txtCenter}>{summary.total_visit}</p>
        </Grid>
      </Grid>
    )
  }

  // func render lịch sử tích stamp và sử dụng của người dùng
  // @return reactnode | null
  renderHistory = () => {
    const { page, limit, count, history, loadingHistory } = this.state
    if (history.length <= 0) return null
    return (
      <Grid item md={12}>
        <TableHistory
          keyProps="id"
          columns={this.columns}
          data={history}
          loading={loadingHistory}
          rowsPerPage={limit}
          maxCount={count}
          page={page > 0 ? page - 1 : 1}
          handleChangePage={this.onChangePage}
          handleChangeRowsPerPage={this.onChangeRowsPerPage}
          language={this.getLanguage()}
        />
      </Grid>
    )
  }

  renderLoading = () => {
    return (
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <CircularProgress size={60} />
      </div>
    )
  }

  renderError = error => {
    return (
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <h3>{this.getLanguage().t(error)}</h3>
      </div>
    )
  }

  renderContent = () => {
    const { loading } = this.state
    if (loading) return this.renderLoading()
    const { language } = this.props
    return (
      <>
        {this.renderAlert()}
        {this.renderInfo()}
        <Card style={{ padding: 12, marginTop: 24 }}>{this.renderInfoStamp()}</Card>
        <Card style={{ padding: 12, marginTop: 24 }}>{this.renderHistory()}</Card>
        <PopupEdit ref={this.refEdit} language={language} onSaveData={this.onSaveData} />
      </>
    )
  }

  render() {
    const { error } = this.state
    if (error) return this.renderError(error)
    return this.renderContent()
  }
}

const mapState = state => {
  return {
    language: state.languageReducer.language,
  }
}

DetailMember.propTypes = {
  language: PropTypes.any,
  match: PropTypes.any,
}

export default connect(mapState)(DetailMember)
