import { getStats, visit_history } from './config'
import request from './request'
import { getMessageErrorFormServer } from '../commons'

/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getMemberList = async ({ authen, page, limit = 10 }) => {
  try {
    const result = await request(authen).get(`${getStats}?page=${page}&limit=${limit}`)
    return {
      success: true,
      data: result.data?.data || [],
      page: {
        current: result.data?.paging?.current_page || 1,
        max: result.data?.paging?.total_page || 10,
      },
      count: result.data?.paging?.total || 0,
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getMemberDetail = async ({ authen, id }) => {
  try {
    const result = await request(authen).get(`${getStats}/${id}`)
    return {
      success: true,
      data: result.data?.data || {},
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}

/**
 * @return {
 *  success: boolean
 *  data?: Array | Object
 *  message?: string
 *  code?: number
 * }
 */
export const getMemberHistory = async ({ authen, id, page, limit }) => {
  try {
    const result = await request(authen).get(`${visit_history(id)}?page=${page}&limit=${limit}`)
    return {
      success: true,
      data: result.data?.data || [],
      page: {
        current: result.data?.paging?.current_page || 1,
        max: result.data?.paging?.total_page || 10,
      },
      count: result.data?.paging?.total || 0,
    }
  } catch (error) {
    return {
      success: false,
      message: getMessageErrorFormServer(error),
    }
  }
}
