export const testMoney = () => {}

/**
 * func format money về dạng 100.000.000
 * @param {*} money
 * @return string
 */
export const formatMoney = money => {
  const real = `${money}`.replace(/\D/g, '')
  return Number(real)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.')
}
