export default {
  voucherList: {
    id: 'Mã Voucher',
    name: 'Tên Voucher',
    usedVoucher: 'Voucher đã dùng',
    emptyLabel: 'Cửa hàng của bạn chưa có voucher nào. Vui lòng liên hệ với Capichi để được hỗ trợ',
    description: 'Danh sách voucher',
    label: 'Voucher',
  },
  stampList: {
    id: 'Mã Stamp',
    type: 'Loại Stamp',
    usedStamp: 'Stamp đã dùng',
    description: 'Danh sách Stamp Card',
    label: 'Stamp Cards',
    emptyLabel:
      'Cửa hàng của bạn chưa có stamp card nào. Vui lòng liên hệ với Capichi để được hỗ trợ.',
  },
  voucherHistory: {
    label: 'Lịch sử Voucher',
    description: 'Thống kê lượt sử dụng voucher của người dùng',
    emptyLabel: 'Hiện chưa có khách hàng nào dùng Voucher',
  },
  stampHistory: {
    label: 'Lịch sử Stamp',
    discount: 'Ưu đãi',
    emptyAutocomplete: 'Hiện khách hàng này chưa sử dụng bất kì stamp nào',
    description: 'Thống kê lượt sử dụng Stamp của người dùng',
    emptyLabel: 'Hiện chưa có khách hàng nào dùng Stamp',
  },
  options: {
    all: 'Tất cả',
    collect: 'Tích stamp',
    used: 'Sử dụng stamp',
  },
  detailStampHistory: {
    storeName: 'Tên nhà hàng',
    user: 'Người dùng',
    quantity: 'Số stamp',
    labelChange: 'Tài khoản: %{user} đã thay đổi giá trị hoá đơn:',
    from: 'Từ',
    label: 'Lịch sử chỉnh sửa',
    more: 'Hiển thị thêm',
  },
  signIn: 'Đăng nhập',
  hello: 'Xin Chào',
  validatePassword: 'Vui lòng nhập mật khẩu từ 8 - 25 kí tự',
  validateField: 'Hãy điền đầy đủ thông tin cho tất cả các mục.',
  validateEmail: 'Vui lòng nhập định dạng email hợp lệ.',
  password: 'Mật khẩu',
  email: 'Địa chỉ email',
  view: 'Xem',
  edit: 'Chỉnh sửa',
  money: 'Số tiền',
  emptyData: 'Không có kết quả',
  title: 'Tiêu đề',
  time: 'Thời gian',
  customer: 'Khách hàng',
  storeName: 'Tên chi nhánh',
  address: 'Địa chỉ',
  image: 'Hình ảnh',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  action: 'Bộ lọc',
  quantity: 'Số lượng',

  rowPerPage: 'Số dòng trên một trang',

  dashboard: {
    sidebar: 'Thống kê',
    used_stamp: 'Sử dụng stamp',
    gender: 'Thống kê theo giới tính',
    age: 'Thống kê theo độ tuổi',
    number_of_visit: 'Số lần đến cửa hàng',
    used_coupon: 'Sử dụng coupon',
    no_data: 'Không có dữ liệu',
  },

  member: {
    title: 'Thành viên của cửa hàng',
    description_: 'Nhà hàng có ',
    _description: ' thành viên',
    sidebar: 'Thành viên',
    average_paying: 'Trung bình',
    visit_frequency: 'Tần suất',
    total_point: 'Số stamp',
    _total: '',
    _point: 'Số stamp',
    total_visit: 'Số lần đến',
    gender: 'Giới tính',
    age: 'Tuổi',
    note: 'Ghi chú',
    number_stt: 'STT',
    time: 'Thời gian',
    date: 'Ngày tháng',
    paying: 'Số tiền trả',
    point: 'Điểm',
    visiting_history: 'Lịch sử đến',
    noData:
      'Hiện chưa có ai sử dụng stamp tại cửa hàng của bạn. Vui lòng liên hệ với Capichi để được hỗ trợ.',
    time_day: '%{time} lần/%{day} ngày',
    day_time: '%{day} ngày/%{time} lần',
  },

  male: 'Nam',
  female: 'Nữ',
  other: 'Khác',
  unknown: 'Không xác định',

  currency: 'VNĐ',

  somethingWrong: 'Đã xảy ra lỗi. Vui lòng thử lại sau.',

  lt20: '<20',
  '20_30': '20-30',
  '30_40': '30-40',
  '40_50': '40-50',
  '50_60': '50-60',
  gt60: '>=60',

  '1': '1',
  '2_5': '1-5',
  '5_10': '5-10',
  gt10: '>=10',

  region: {
    vietnam: 'Người Việt Nam',
    japan: 'Người Nhật',
    korean: 'Người Hàn',
  },

  save: 'Lưu',
  errorAge: 'Vui lòng nhập độ tuổi từ 13 đến 100',

  updateSuccess: 'Cập nhật thành công!',
  content: 'Nội dung',
  errorNotification: {
    requiredTitle: 'Vui lòng nhập tiêu đề cho thông báo!',
    requiredContent: 'Vui lòng nhập nội dung cho thông báo!',
  },
  pushSuccess:
    'Thông báo của bạn đã được gửi thành công đến ban quản trị. Vui lòng đợi phản hồi từ phía ban quản trị về việc gửi thông báo cho người dùng.',
  addNewImage: 'Thêm ảnh',
  nationality: 'Quốc gia',
  language: {
    vi: 'Tiếng Việt',
    ja: '日本語',
    ko: '한국',
    en: 'English',
  },
}
