import { connect } from 'react-redux'
import PieModule from '../Modules/Pie'
import { AUTHEN_TOKEN } from '../../../constants/define'
import { getGenderCoupon } from '../../../api/analytic'

class GenderCoupon extends PieModule {
  getAuthenToken = () => {
    return localStorage.getItem(AUTHEN_TOKEN)
  }

  callApi = (...arg) => {
    return getGenderCoupon(...arg)
  }

  getTitle = () => {
    const language = this.getLanguage()
    return language.t('dashboard.gender')
  }

  // lấy ra label của biểu đồ
  getLabels = () => {
    const { dataPie } = this.state
    return dataPie.map(i => this.getLanguage().t(`${i.label}`.toLowerCase()))
  }

  getLanguage = () => {
    const { language } = this.props
    return language
  }
}

const mapState = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapState)(GenderCoupon)
