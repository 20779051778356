import React from 'react'
import { Line } from 'react-chartjs-2'
import PropTypes from 'prop-types'

class LineChart extends React.PureComponent {
  // func dựa vào mảng data có sẵn để chuyển sang dataset tương ứng với biểu đồ
  getDataSets = () => {
    const { data, labels } = this.props
    return {
      labels,
      datasets: [
        {
          label: '',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data,
        },
      ],
    }
  }

  render() {
    const { title, data, labels, ...props } = this.props

    return (
      <div>
        {title ? <h3>{title}</h3> : null}
        <Line
          options={{
            scales: {
              yAxes: [
                {
                  ticks: { suggestedMin: 0, suggestedMax: 50 },
                },
              ],
            },
          }}
          data={this.getDataSets(data)}
          legend={{ display: false }}
          {...props}
        />
      </div>
    )
  }
}

LineChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  labels: PropTypes.array,
}

export default LineChart
