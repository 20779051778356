import { connect } from 'react-redux'
import PieModule from '../Modules/Pie'
import { AUTHEN_TOKEN } from '../../../constants/define'
import { getAgeCoupon } from '../../../api/analytic'

class AgeCoupon extends PieModule {
  getAuthenToken = () => {
    return localStorage.getItem(AUTHEN_TOKEN)
  }

  callApi = (...arg) => {
    return getAgeCoupon(...arg)
  }

  getTitle = () => {
    const language = this.getLanguage()
    return language.t('dashboard.age')
  }

  getLanguage = () => {
    const { language } = this.props
    return language
  }
}

const mapState = state => {
  return {
    language: state.languageReducer.language,
  }
}

export default connect(mapState)(AgeCoupon)
