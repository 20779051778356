// func danh cho việc tính toán tần xuất ge thăm cửa hàng
// return {
//  time
//  day
// }

export default visit => {
  if (!visit)
    return {
      time: 0,
      day: 1,
    }

  if (visit > 1 || visit === 0) {
    return {
      day: 1,
      time: Math.round(visit * 10) / 10,
    }
  }

  return {
    day: Math.round(10 / visit) / 10,
    time: 1,
  }
}
