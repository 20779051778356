/* eslint-disable no-undef */
import request from './request'
import { push_notification } from './config'
import { getMessageErrorFormServer } from '../commons'

export const pushNotificationApi = async ({ authen, ...props }) => {
  try {
    const formData = new FormData()

    const key = _.keys(props)

    for (let index = 0; index < key.length; index += 1) {
      const element = key[index]
      if (props[element] !== undefined && Array.isArray(props[element])) {
        if (element === 'translations') {
          props[element].forEach((elementTranslate, indexTranslate) => {
            formData.append(
              `announcement[announcement_translations_attributes][${indexTranslate}][title_translation]`,
              elementTranslate.title
            )
            formData.append(
              `announcement[announcement_translations_attributes][${indexTranslate}][content_translation]`,
              elementTranslate.content
            )
            formData.append(
              `announcement[announcement_translations_attributes][${indexTranslate}][language_id]`,
              elementTranslate.id
            )
          })
        } else {
          props[element].forEach(elementTranslate => {
            formData.append(`announcement[${element}][]`, elementTranslate.value)
          })
        }
      } else if (props[element] !== undefined) {
        formData.append(`announcement[${element}]`, props[element])
      }
    }
    const { data } = await request(authen).post(`${push_notification}`, formData)
    return {
      status: 'success',
      data,
    }
  } catch (error) {
    return {
      status: 'failed',
      message: getMessageErrorFormServer(error),
    }
  }
}

export const testPushNotify = () => {}
