import React from 'react'
import PropTypes from 'prop-types'

const MaxLength = ({ current, max }) => {
  return (
    <p
      style={{
        textAlign: 'right',
        marginBottom: '-15px',
        marginTop: 0,
      }}
    >
      {current}/{max}
    </p>
  )
}

MaxLength.propTypes = {
  current: PropTypes.any,
  max: PropTypes.any,
}

export default MaxLength
