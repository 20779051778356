/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { CircularProgress } from '@material-ui/core'
import Proptypes from 'prop-types'
// style cho bảng
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    height: '70vh',
    // padding: 0,
  },
  container2: {
    height: '65vh',
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  buttonfilter: {
    marginRight: '8px',
    backgroundColor: 'red',
    color: '#fff',
  },
  position: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,

    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    zIndex: 10,
  },
  back: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  filterContainer: {
    flexWrap: 'nowrap',
  },
  buttonCreate: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleFilter: {
    color: '#000000',
    marginRight: '8px',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
})

function TableHistory({
  columns,
  keyProps,
  maxCount,
  data,
  loading,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  language,
}) {
  const classes = useStyles()

  return (
    <div style={{ position: 'relative' }}>
      <>
        <TableContainer className={classes.container2}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    id={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      fontSize: 16,
                      fontWeight: '500',
                    }}
                  >
                    {language.t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row[keyProps]}
                    style={{
                      backgroundColor: '#fff',
                    }}
                  >
                    {columns.map(column => {
                      const value = row[column.id]
                      return (
                        <TableCell
                          style={{ wordBreak: 'break-word', fontSize: 16, fontWeight: '300' }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format ? column.format(value, row, index) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={maxCount || data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={language.t('rowPerPage')}
        />
      </>
      {loading ? (
        <div className={data.length > 0 ? classes.back : classes.position}>
          <CircularProgress size={60} />
        </div>
      ) : null}
    </div>
  )
}

TableHistory.propTypes = {
  columns: Proptypes.array,
  keyProps: Proptypes.string,
  maxCount: Proptypes.number,
  data: Proptypes.array,
  loading: Proptypes.bool,
  page: Proptypes.number,
  rowsPerPage: Proptypes.number,
  handleChangePage: Proptypes.func,
  handleChangeRowsPerPage: Proptypes.func,
  language: Proptypes.any.isRequired,
}

TableHistory.defaultProps = {
  columns: [],
}

export default TableHistory
