import React from 'react'
import { CircularProgress, Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import { I18n } from '../../../config'
import PieChart from './PieChart'

class PieModule extends React.Component {
  constructor(props) {
    super(props)
    // lấy ra init state để hiển thị
    this.state = {
      loading: typeof props.initState?.loading === 'boolean' ? props.initState?.loading : true,
      dataPie: Array.isArray(props.initState?.data) ? props.initState?.data : [],
      error: props.initState?.error,
    }
  }

  // nếu ở trạng thái loading khi khởi tạo, mới lấy data
  componentDidMount() {
    const { loading } = this.state
    if (loading) this.onGetData()
  }

  // func lấy ra api
  // sử dụng override
  callApi = () => {
    return () => {}
  }

  onGetData = () => {
    this.callApi({ authen: this.getAuthenToken() }).then(r => {
      if (r.success) {
        this.setState({ loading: false, dataPie: r.data })
      } else {
        this.setState({ loading: false, error: this.setError() || r.message })
      }
    })
  }

  // func override: lấy ra token
  getAuthenToken = () => {}

  // lấy ra tiêu đề cho biểu đồ
  getTitle = () => {
    return ''
  }

  // lấy ra data cho biểu đồ
  getData = () => {
    const { dataPie } = this.state
    return dataPie.map(i => i.value)
  }

  // lấy ra label của biểu đồ
  getLabels = () => {
    const { dataPie } = this.state
    return dataPie.map(i => `${this.getLanguage().t(i.label)}`)
  }

  // func set error khi gọi api
  setError = () => {
    return ''
  }

  // func lấy ra error state
  getError = () => {
    const { error } = this.state
    return error
  }

  // func phục vụ override
  getMessageNoData = () => {
    const language = this.getLanguage()
    return language.t('dashboard.no_data')
  }

  getLanguage = () => {
    return I18n
  }

  // check array data: nếu tất cả value đều = 0 => return true
  checkNoData = () => {
    const { dataPie } = this.state
    const check = dataPie.reduce((a, b) => {
      return a || b.value > 0
    }, false)

    return !check
  }

  renderLoading = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '20px',
          height: 200,
        }}
      >
        <CircularProgress
          size={40}
          color="inherit"
          style={{ color: '#000', marginBottom: '20px' }}
        />
      </div>
    )
  }

  renderError = error => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '20px',
          height: 200,
        }}
      >
        <p>{error}</p>
      </div>
    )
  }

  renderPie = () => {
    return <PieChart data={this.getData()} labels={this.getLabels()} />
  }

  // hiển thị lỗi hoặc content
  renderContent = () => {
    const { error } = this.state
    if (error) return this.renderError(error)
    // kiểm tra xem có đầy đủ data ko
    if (this.checkNoData()) return this.renderError(this.getMessageNoData())

    return this.renderPie()
  }

  // render cả component
  renderContainer = () => {
    const { loading } = this.state
    return (
      <div style={{ margin: '0px', width: '30%' }}>
        <Card style={{ padding: '24px', height: 320 }}>
          <div>
            {this.getTitle() ? <h4 style={{ height: 45 }}>{this.getTitle()}</h4> : null}
            {loading ? this.renderLoading() : this.renderContent()}
          </div>
        </Card>
      </div>
    )
  }

  render() {
    return this.renderContainer()
  }
}

PieModule.propTypes = {
  initState: PropTypes.object,
}

export default PieModule
