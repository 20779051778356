export default {
  voucherList: {
    id: 'クーポン番号',
    name: 'クーポン名',
    usedVoucher: 'クーポン利用数',
    emptyLabel:
      'あなたの店舗はまだどのクーポンも設定していません。Capichi運営に連絡してサポートしてもらってください',
    description: 'クーポン一覧',
    label: 'クーポン',
  },
  stampList: {
    id: 'ポイントカード番号',
    type: '種類',
    usedStamp: 'ポイントカード利用数',
    description: 'ポイントカード一覧',
    label: 'ポイントカード',
    emptyLabel:
      'あなたの店舗はまだどのポイントカードも設定していません。Capichi運営に連絡してサポートしてもらってください',
  },
  voucherHistory: {
    label: 'クーポン',
    description: 'ユーザーのクーポン利用データ',
    emptyLabel: 'まだどのお客様もクーポンを使っていません',
  },
  stampHistory: {
    label: 'ポイントカード使用履歴',
    discount: '与える',
    emptyAutocomplete: 'まだこのお客様はどのポイントカードも使っていません',
    description: 'ポイントカードの利用履歴一覧',
    emptyLabel: 'まだどのお客様もポイントカードを使っていません',
  },
  options: {
    all: 'すべて',
    collect: 'ポイントの獲得',
    used: 'ポイントの利用',
  },
  detailStampHistory: {
    storeName: '店舗名',
    user: 'お客様名',
    quantity: '獲得ポイント',
    labelChange: 'アカウント%{user} がお会計額を変更しました',
    from: '',
    label: '編集履歴',
    more: '続きを見る',
  },
  signIn: 'サインイン',
  hello: 'こんにちは',
  validatePassword: 'パスワードは8文字以上で、25文字を超えることはできません。',
  validateField: 'すべてのフィールドに記入してください',
  validateEmail: '正しいフォーマットでメールアドレスを入力してください',
  password: 'パスワード',
  email: 'メールアドレス',
  view: '詳細',
  edit: '編集',
  money: 'お会計金額',
  emptyData: '結果がありません',
  title: 'タイトル',
  time: '使用された時間',
  customer: 'お客様名',
  storeName: '店舗名',
  address: '住所',
  image: '写真',
  startDate: '利用開始日',
  endDate: '利用終了日',
  action: '利用方法',
  quantity: '獲得ポイント',

  rowPerPage: 'ページあたりの行数',

  dashboard: {
    sidebar: 'ダッシュボード',
    used_stamp: 'ポイントカードの利用数',
    gender: '性別',
    age: '年齢',
    number_of_visit: '来店回数',
    used_coupon: 'クーポンの利用',
    no_data: 'データなし',
  },
  member: {
    title: '店舗会員リスト',
    description_: '合計ポイント： ',
    _description: '人',
    sidebar: '会員',
    average_paying: '平均会計金額',
    visit_frequency: '来店頻度',
    total_point: '登録者数',
    _total: '合計',
    _point: 'ポイント',
    total_visit: '来店回数合計',
    gender: '性別',
    age: '年齢',
    note: 'メモ',
    number_stt: 'No.',
    time: '時間',
    date: '日付',
    paying: 'お会計額',
    point: 'ポイント',
    visiting_history: '来店履歴',
    noData: '現時点で店舗のポイントカードを利用したお客様がいないのでデータは表示されません',
    time_day: '%{time}回/%{day}日',
    day_time: '%{day}日/%{time}回',
  },

  currency: 'VNĐ',

  male: '男性',
  female: '女性',
  other: 'その他',
  unknown: '不明',

  somethingWrong: '店舗情報の更新に失敗しました。もう一度お試しください',

  lt20: '<20',
  '20_30': '20-30',
  '30_40': '30-40',
  '40_50': '40-50',
  '50_60': '50-60',
  gt60: '>=60',

  region: {
    vietnam: 'ベトナム人',
    japan: '日本人',
    korean: '韓国語',
  },
  '1': '1',
  '1_5': '1-5',
  '5_10': '5-10',
  gt10: '>=10',

  save: '保存',
  errorAge: 'Vui lòng nhập độ tuổi từ 13 đến 100',

  updateSuccess: '更新に成功しました。',
  content: 'コンテンツ',
  errorNotification: {
    requiredTitle: '通知のタイトルを入力してください！',
    requiredContent: 'お知らせの内容を入力してください！',
  },
  pushSuccess:
    'メッセージは管理者に送信されました。 ユーザーへの通知の送信に関する管理者からの応答をお待ちください。',
  addNewImage: '画像の追加',
  nationality: '国籍',
  language: {
    vi: 'Tiếng Việt',
    ja: '日本語',
    ko: '한국',
    en: 'English',
  },
}
