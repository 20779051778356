export const AUTHEN_TOKEN = 'authenToken_capichi_crm'
export const USER_ID = 'USER_ID'
export const DEVICE_TOKEN = 'deviceToken_capichi_crm'

export const formatTime = {
  date: 'DD-MM-YYYY',
  date2: 'YYYY-MM-DD',
  d: 'DD',
  m: 'MM',
  time: 'HH:mm',
}

// gioi tinh
export const gender = [
  { label: 'male', value: 0 },
  { label: 'female', value: 1 },
  { label: 'other', value: 2 },
]

// cac danh sách default
export const listDefault = {
  gender: [
    { id: 1, name: 'male', check: false, value: 'male' },
    { id: 2, name: 'female', check: false, value: 'female' },
    { id: 3, name: 'other', check: false, value: 'other' },
    { id: 4, name: 'unknown', check: false, value: 'unknown' },
  ],
  age: [
    { id: 1, name: 'lt20', check: false, value: 'lt_20' },
    { id: 2, name: '20_30', check: false, value: 'in_20_30' },
    { id: 3, name: '30_40', check: false, value: 'in_30_40' },
    { id: 4, name: '40_50', check: false, value: 'in_40_50' },
    { id: 5, name: '50_60', check: false, value: 'in_50_60' },
    { id: 6, name: 'gt60', check: false, value: 'gte_60' },
    { id: 7, name: 'unknown', check: false, value: 'unknown' },
  ],
  nationality: [
    { id: 1, name: 'region.vietnam', check: false, value: 'Viet Nam' },
    { id: 2, name: 'region.japan', check: false, value: 'Japan' },
    { id: 3, name: 'region.korean', check: false, value: 'Korea, Republic of' },
    { id: 4, name: 'other', check: false, value: 'others' },
    { id: 5, name: 'unknown', check: false, value: 'unknown' },
  ],
  numbervisit: [
    { id: 1, name: '1', check: false, value: 'eq_1' },
    { id: 2, name: '1_5', check: false, value: 'in_1_5' },
    { id: 3, name: '5_10', check: false, value: 'in_5_10' },
    { id: 4, name: 'gt10', check: false, value: 'gteq_10' },
    { id: 5, name: 'unknown', check: false, value: 'unknown' },
  ],
}

// ngôn ngữ
export const languageList = [
  { id: 1, name: 'ja', label: 'ja' },
  { id: 2, name: 'vi', label: 'vi' },
  { id: 3, name: 'en', label: 'en' },
  { id: 4, name: 'ko', label: 'ko' },
]

export const selectData = {
  gender: 'gender',
  age: 'age',
  nationality: 'nationality',
  numberVisit: 'numberVisit',
}

// Dinh nghia cac field cua Language
export const languageName = {
  vi: 'language.vi',
  ja: 'language.ja',
  ko: 'language.ko',
  en: 'language.en',
}

export const languageKey = {
  vi: 'vi',
  ja: 'ja',
  ko: 'ko',
  en: 'en',
}

export const languageListKey = {
  vi: 2,
  ja: 1,
  ko: 4,
  en: 3,
}

export const titleLanguage = {
  vi: 'titleVi',
  ja: 'titleJa',
  ko: 'titleKo',
  en: 'titleEn',
}

export const contentLanguage = {
  vi: 'contentVi',
  ja: 'contentJa',
  ko: 'contentKo',
  en: 'contentEn',
}
