/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
// core components/views for Admin layout
import DashboardPage from './views/Dashboard'
// import Typography from './views/Typography/Typography'
// core components/views for RTL layout
import VoucherList from './views/History/VoucherList'
import StampList from './views/History/StampList'
import DetailStamp from './views/History/DetailStamp'
import VoucherTable from './views/Voucher'
import StampCardTable from './views/StampCard'
import MemberList from './views/Member'
import DetailMember from './views/Member/Detail'
import PushNotification from './views/PushNotificaiton'

const brandRouters = [
  {
    path: '/detailStamp/:id',
    name: 'Chi tiết Stamp',
    icon: LibraryBooks,
    component: DetailStamp,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/memberlist/:id',
    name: 'Chi tiết Stamp',
    icon: LibraryBooks,
    component: DetailMember,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/pushnotification',
    name: '',
    icon: LibraryBooks,
    component: PushNotification,
    layout: '/admin',
    invisible: true,
  },
]

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard.sidebar',
    icon: DashboardIcon,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/memberlist',
    name: 'member.sidebar',
    icon: LibraryBooks,
    component: MemberList,
    layout: '/admin',
  },

  {
    path: '/voucher',
    name: 'voucherList.label',
    icon: LibraryBooks,
    component: VoucherTable,
    layout: '/admin',
  },
  {
    path: '/stampCard',
    name: 'stampList.label',
    icon: LibraryBooks,
    component: StampCardTable,
    layout: '/admin',
  },
  // history
  {
    path: '/historyVoucher',
    name: 'voucherHistory.label',
    icon: LibraryBooks,
    component: VoucherList,
    layout: '/admin',
  },
  {
    path: '/stampHistory',
    name: 'stampHistory.label',
    icon: LibraryBooks,
    component: StampList,
    layout: '/admin',
  },
]

const mainRouters = [...brandRouters, ...dashboardRoutes]

export default mainRouters
