import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import GridContainer from '../../components/Grid/GridContainer'
import LineUsedStamp from './Stamp/LineUsedStamp'
import LineUsedCoupon from './Coupon/LineUseCoupon'
import AgeStamp from './Stamp/PieAgeStamp'
import GenderStamp from './Stamp/PieGenderStamp'
import NumberVisitStamp from './Stamp/PieNumberVisitStamp'
import GenderCoupon from './Coupon/PieGenderCoupon'
import AgeCoupon from './Coupon/PieAgeCoupon'
import {
  getGenderCoupon,
  getAgeCoupon,
  getGenderStamp,
  getUsedStampHistory,
  getAgeStamp,
  getNumberOfVisits,
  getUsedCouponHistory,
} from '../../api/analytic'
import { AUTHEN_TOKEN } from '../../constants/define'
import '../../assets/css/Chart/styles.css'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: '',
      // state line stamp
      lineStamp: {
        loading: true,
        error: '',
        data: [],
        today: {},
      },
      // state pie gender stamp
      pieGenderStamp: {
        loading: true,
        error: '',
        data: [],
      },
      // state pie age stamp
      pieAgeStamp: {
        loading: true,
        error: '',
        data: [],
      },
      // state pie visit_stamp
      pieNumberVisitStamp: {
        loading: true,
        error: '',
        data: [],
      },

      // state line coupon
      lineCoupon: {
        loading: true,
        error: '',
        data: [],
        today: {},
      },
      // state pie gender coupon
      pieGenderCoupon: {
        loading: true,
        error: '',
        data: [],
      },
      // state pie age coupon
      pieAgeCoupon: {
        loading: true,
        error: '',
        data: [],
      },
    }
  }

  componentDidMount() {
    this.onGetData()
  }

  getAuthen = () => {
    return localStorage.getItem(AUTHEN_TOKEN)
  }

  getLanguage = () => {}

  // onGetData gọi 7 api lấy dữ liệu cho từng biêu đồ
  onGetData = async () => {
    Promise.all([
      await this.onGetUseStamp(),
      await this.onGetGenderStamp(),
      await this.onGetAgeStamp(),
      await this.onGetNumberOfVisits(),
      await this.onGetUseCoupon(),
      await this.onGetGenderCoupon(),
      await this.onGetAgeCoupon(),
    ]).then(
      ([
        lineStamp,
        pieGenderStamp,
        pieAgeStamp,
        pieNumberVisitStamp,
        lineCoupon,
        pieGenderCoupon,
        pieAgeCoupon,
      ]) => {
        // nếu tất cả đều lỗi, sẽ xuất hiện lỗi chung
        if (
          !lineStamp.success &&
          !pieGenderStamp.success &&
          !pieAgeStamp.success &&
          !pieNumberVisitStamp.success &&
          !lineCoupon.success &&
          !pieGenderCoupon.success &&
          !pieAgeCoupon.success
        ) {
          this.setState({ loading: false, error: 'Đã xảy ra lỗi. Vui lòng thử lại sau' })
        } else
          this.setState({
            lineStamp,
            pieGenderStamp,
            pieAgeStamp,
            pieNumberVisitStamp,
            lineCoupon,
            pieGenderCoupon,
            pieAgeCoupon,
            loading: false,
          })
      }
    )
  }

  // func goi api lấy thông tin sử dụng của stamp
  // @return promise
  onGetUseStamp = () => {
    return new Promise(resolve => {
      const authen = this.getAuthen()
      getUsedStampHistory({ authen }).then(r => {
        if (r.success) {
          resolve({ success: true, loading: false, data: r.data, error: '', today: r.today })
        } else
          resolve({ success: false, loading: false, data: [], error: r.message || '', today: {} })
      })
    })
  }

  // func goi api lấy thông tin gender của stamp
  // @return promise
  onGetGenderStamp = () => {
    return new Promise(resolve => {
      const authen = this.getAuthen()
      getGenderStamp({ authen }).then(r => {
        if (r.success) {
          resolve({ success: true, loading: false, data: r.data, error: '' })
        } else resolve({ success: false, loading: false, data: [], error: r.message || '' })
      })
    })
  }

  // func gọi api lấy thông tin age của stamp
  // @return promise
  onGetAgeStamp = () => {
    return new Promise(resolve => {
      const authen = this.getAuthen()
      getAgeStamp({ authen }).then(r => {
        if (r.success) {
          resolve({ success: true, loading: false, data: r.data, error: '' })
        } else resolve({ success: false, loading: false, data: [], error: r.message || '' })
      })
    })
  }

  // func gọi api lấy thông tin number of visits
  // @return promise
  onGetNumberOfVisits = () => {
    return new Promise(resolve => {
      const authen = this.getAuthen()
      getNumberOfVisits({ authen }).then(r => {
        if (r.success) {
          resolve({ success: true, loading: false, data: r.data, error: '' })
        } else resolve({ success: false, loading: false, data: [], error: r.message || '' })
      })
    })
  }

  // func gọi api lấy thông tin sử dụng coupon
  // @return promise
  onGetUseCoupon = () => {
    return new Promise(resolve => {
      const authen = this.getAuthen()
      getUsedCouponHistory({ authen }).then(r => {
        if (r.success) {
          resolve({ success: true, loading: false, data: r.data, error: '', today: r.today })
        } else
          resolve({ success: false, loading: false, data: [], error: r.message || '', today: {} })
      })
    })
  }

  // func gọi api lấy thông tin gender của coupon
  // @return promise
  onGetGenderCoupon = () => {
    return new Promise(resolve => {
      const authen = this.getAuthen()
      getGenderCoupon({ authen }).then(r => {
        if (r.success) {
          resolve({ success: true, loading: false, data: r.data, error: '' })
        } else resolve({ success: false, loading: false, data: [], error: r.message || '' })
      })
    })
  }

  // func gọi api lấy thông tin age của coupon
  // @return promise
  onGetAgeCoupon = () => {
    return new Promise(resolve => {
      const authen = this.getAuthen()
      getAgeCoupon({ authen }).then(r => {
        if (r.success) {
          resolve({ success: true, loading: false, data: r.data, error: '' })
        } else resolve({ success: false, loading: false, data: [], error: r.message || '' })
      })
    })
  }

  // func render cho biểu đồ gender của stamp
  renderGenderStamp = () => {
    const { pieGenderStamp } = this.state
    return <GenderStamp initState={pieGenderStamp} />
  }

  // func render cho biểu đồ age của stamp
  renderAgeStamp = () => {
    const { pieAgeStamp } = this.state
    return <AgeStamp initState={pieAgeStamp} />
  }

  // fucn render cho mục number of visit
  renderNumberOfVisitStamp = () => {
    const { pieNumberVisitStamp } = this.state
    return <NumberVisitStamp initState={pieNumberVisitStamp} />
  }

  // func render cho muc stamp
  // bao gồm lượng sử dụng và các biểu đồ tròn
  renderStamp = () => {
    const { lineStamp } = this.state
    return (
      <>
        <GridContainer>
          <LineUsedStamp initState={lineStamp} />
        </GridContainer>

        <GridContainer>
          <Grid item md={9} st>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 24,
              }}
            >
              {this.renderGenderStamp()}
              {this.renderAgeStamp()}
              {this.renderNumberOfVisitStamp()}
            </Grid>
          </Grid>
        </GridContainer>
      </>
    )
  }

  // func render cho biểu đồ gender của coupon
  renderGenderCoupon = () => {
    const { pieGenderCoupon } = this.state
    return <GenderCoupon initState={pieGenderCoupon} />
  }

  // func render cho biểu đồ age của coupon
  renderAgeCoupon = () => {
    const { pieAgeCoupon } = this.state
    return <AgeCoupon initState={pieAgeCoupon} />
  }

  // func render cho muc coupon

  renderCoupon = () => {
    const { lineCoupon } = this.state
    return (
      <>
        <GridContainer>
          <LineUsedCoupon initState={lineCoupon} />
        </GridContainer>

        <GridContainer>
          <Grid item md={9} st>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 24,
              }}
            >
              {this.renderGenderCoupon()}
              {this.renderAgeCoupon()}
              <div style={{ width: '30%' }} />
            </Grid>
          </Grid>
        </GridContainer>
      </>
    )
  }

  renderContent = () => {
    const { loading } = this.state
    if (loading) return this.renderLoading()
    return (
      <div>
        {this.renderStamp()}
        {this.renderCoupon()}
      </div>
    )
  }

  renderLoading = () => {
    return (
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <CircularProgress size={60} />
      </div>
    )
  }

  renderError = () => {
    const { error } = this.state
    return (
      <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
        <h3>{error}</h3>
      </div>
    )
  }

  render() {
    const { error } = this.state
    if (error) return this.renderError()

    return this.renderContent()
  }
}

export default Dashboard
