/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import {
  Dialog,
  Grid,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import IconClose from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { gender as genderDefault, AUTHEN_TOKEN } from '../../constants/define'
import MaxLength from '../../components/MaxLength'
import { updateUserStore } from '../../api/profile'
import { emojiToString } from '../../commons'

const maxNote = 1024
const minAge = 13
const maxAge = 100

class PopupEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,

      user_id: '',
      gender: '',
      age: '',
      errorAge: '',

      note: '',
      loading: false,

      error: '',
    }
  }

  show = ({ gender, age, note, id }) => {
    this.setState({
      gender: gender || '',
      age: age || '',
      note: note || '',
      loading: false,
      error: '',
      errorAge: '',
      show: true,
      user_id: id,
    })
  }

  /**
   * func đóng popup
   * check xem đang loading update data hay không
   * nếu có thì không cho phép đong
   */
  onClose = () => {
    const { loading } = this.state
    if (loading) return

    this.setState({ show: false })
  }

  /**
   * func chỉnh sửa giới tính khi lựa chọn từ selectbox
   */
  onChangeGender = event => {
    this.setState({ gender: event.target.value })
  }

  /**
   * func chỉnh sửa tuổi từ input
   * loai bỏ mọi ký tự khong phai là số
   * 12 < age <=100
   */
  onChangeAge = event => {
    const { value } = event.target
    const realValue = value.replace(/[^0-9]/g, '')

    this.setState({
      age: realValue,
      errorAge:
        realValue.length <= 0 || (Number(realValue) >= minAge && Number(realValue) <= maxAge)
          ? ''
          : 'errorAge',
    })
  }

  /**
   * func chinhr suwa note từ input
   * giới hạn 200
   */
  onChangeNote = event => {
    const { value } = event.target
    this.setState({ note: value.slice(0, maxNote) })
  }

  // gọi api để lưu thông tin
  // thành công sẽ gọi lại callback từ component cha để cập nhật
  onSubmit = () => {
    const { errorAge } = this.state
    if (errorAge) return
    this.setState({ loading: true }, () => {
      const authen = localStorage.getItem(AUTHEN_TOKEN)

      const { gender, age, note, user_id } = this.state
      updateUserStore({ authen, gender, age, note: emojiToString(note), id: user_id }).then(r => {
        if (r.success) {
          const { onSaveData } = this.props

          this.setState({ show: false }, () => {
            if (typeof onSaveData === 'function') onSaveData({ gender, age, note })
          })
        } else {
          this.setState({ error: 'somethingWrong', loading: false }, () => {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
              this.setState({ error: '' })
            }, 5000)
          })
        }
      })
    })
  }

  // render header (bao gồm mỗi cái close)
  renderHeader = () => {
    return (
      <div onClick={this.onClose}>
        <IconClose />
      </div>
    )
  }

  // render phần chỉnh sửa giới tính (select box)
  renderGender = () => {
    const { gender } = this.state
    const { language } = this.props
    return (
      <Grid container>
        <Grid item md={3}>
          <span>{language.t('member.gender')}</span>
        </Grid>
        <Grid item md={8}>
          <Select style={{ width: '100%' }} value={gender} onChange={this.onChangeGender}>
            {genderDefault.map(item => {
              return (
                <MenuItem key={`${item.value}`} value={item.label}>
                  {language.t(item.label)}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
      </Grid>
    )
  }

  // render phan age: input nhập
  renderAge = () => {
    const { language } = this.props
    const { age, errorAge } = this.state
    return (
      <Grid container alignItems="center" style={{ padding: '12px 0px' }}>
        <Grid item md={3}>
          <span>{language.t('member.age')}</span>
        </Grid>
        <Grid item md={8}>
          <TextField
            error={!!errorAge}
            helperText={errorAge ? language.t(errorAge) : ''}
            value={age}
            onChange={this.onChangeAge}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    )
  }

  // render phan note: nhap ghi chu
  renderNote = () => {
    const { language } = this.props
    const { note } = this.state
    return (
      <Grid container>
        <Grid item md={3}>
          <span>{language.t('member.note')}</span>
        </Grid>
        <Grid item md={8}>
          <TextField
            multiline
            value={note}
            rows={4}
            onChange={this.onChangeNote}
            style={{ width: '100%' }}
          />
          <MaxLength current={note.length} max={maxNote} />
        </Grid>
      </Grid>
    )
  }

  renderSubmit = () => {
    const { language } = this.props
    const { loading } = this.state
    return (
      <Grid container style={{ marginTop: 15 }}>
        <Grid item md={3} />
        <Grid item md={8}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#F7941D', width: 80, alignItems: 'center' }}
            onClick={this.onSubmit}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" style={{ color: '#fff' }} />
            ) : (
              language.t('save')
            )}
          </Button>
        </Grid>
      </Grid>
    )
  }

  renderAlertError = () => {
    const { error } = this.state
    if (!error) return null
    const { language } = this.props
    return <Alert severity="error">{language.t(error)}</Alert>
  }

  // render content
  renderContent = () => {
    return (
      <div style={{ marginTop: 15 }}>
        {this.renderGender()}
        {this.renderAge()}
        {this.renderNote()}
        {this.renderSubmit()}
      </div>
    )
  }

  // render container
  renderContainer = () => {
    const { show } = this.state
    return (
      <Dialog
        id="order-container"
        classes={{ paperFullWidth: 'dialog-review' }}
        fullWidth
        open={show}
        // onClose={this.onClose}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ padding: 20 }}>
          {this.renderHeader()}
          {this.renderAlertError()}
          {this.renderContent()}
        </div>
      </Dialog>
    )
  }

  // func render chính

  render() {
    return this.renderContainer()
  }
}

PopupEdit.propTypes = {
  language: PropTypes.any,
  onSaveData: PropTypes.func,
}

export default PopupEdit
