import React from 'react'
import { Grid, Card, CircularProgress } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import LineChart from './LineChart'
import '../../../assets/css/Chart/styles.css'
import { I18n } from '../../../config'
import { formatTime } from '../../../constants/define'

class LineModule extends React.Component {
  constructor(props) {
    super(props)

    // sử dụng props init state
    this.state = {
      loading: typeof props.initState?.loading === 'boolean' ? props.initState?.loading : true,
      dataLine: Array.isArray(props.initState?.data) ? props.initState?.data : [],
      error: props.initState?.error,
    }
  }

  // chỉ gọi api khi trnajg thai là loading
  componentDidMount() {
    const { loading } = this.state
    if (loading) this.onGetData()
  }

  // func override: dưa ra func api muốn gọi
  callApi = () => {
    return () => {}
  }

  onGetData = () => {
    this.callApi({ authen: this.getAuthenToken() }).then(r => {
      if (r.success) {
        this.setState({ loading: false, dataLine: r.data })
      } else {
        this.setState({ loading: false, error: this.setError() || r.message })
      }
    })
  }

  // func lấy ra token
  getAuthenToken = () => {}

  // lấy ra title cho biểu đồ
  getTitle = () => {
    return ''
  }

  // lấy ra data cho biểu đồ
  getData = () => {
    const { dataLine } = this.state
    return dataLine.map(i => i.value)
  }

  // lấy ra label cho biểu đồ
  getLabels = () => {
    const { dataLine } = this.state
    const labels = dataLine.map(i => {
      // lấy ra thông tin ngày, tháng dựa vào dữ liệu của api
      const d = moment(i.label, formatTime.date2).format(formatTime.d)
      const m = moment(i.label, formatTime.date2).format(formatTime.m)
      // nếu không phải ngày 01 thì ko hiển thị thông tin tháng
      // if (Number(d) <= 1) return [d, m]
      return `${d}/${m}`
    })
    return labels
  }

  // object
  getObjectToday = () => {
    return 'today'
  }

  getToday = () => {}

  // error muôn set khi goi api
  setError = () => {
    return ''
  }

  getError = () => {
    const { error } = this.state
    return error
  }

  getLanguage = () => {
    return I18n
  }

  renderLoading = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '20px',
          height: 350,
        }}
      >
        <CircularProgress
          size={40}
          color="inherit"
          style={{ color: '#000', marginBottom: '20px' }}
        />
      </div>
    )
  }

  renderError = () => {
    const { error } = this.state
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '20px',
          height: 350,
        }}
      >
        {error}
      </div>
    )
  }

  renderLine = () => {
    return <LineChart data={this.getData()} labels={this.getLabels()} />
  }

  renderContent = () => {
    const { error } = this.state
    if (error) this.renderError()
    return (
      <Grid container>
        <Grid item md={12}>
          {this.renderLine()}
        </Grid>
      </Grid>
    )
  }

  renderContainer = () => {
    const { loading } = this.state
    return (
      <Grid item xs={12} sm={12} md={9} style={{ margin: '24px' }}>
        <Card style={{ padding: '24px' }}>
          <div>
            {this.getTitle() ? <h4>{this.getTitle()}</h4> : null}
            {loading ? this.renderLoading() : this.renderContent()}
          </div>
        </Card>
      </Grid>
    )
  }

  render() {
    return this.renderContainer()
  }
}

LineModule.propTypes = {
  initState: PropTypes.object,
}

export default LineModule
