export default {
  voucherList: {
    id: 'Voucher Id',
    name: 'Voucher name',
    usedVoucher: 'Used Voucher',
    emptyLabel:
      'Your restaurant does not have any voucher. Please contact to Capichi for supporting',
    description: 'Voucher List',
    label: 'Voucher',
  },
  stampList: {
    id: 'Stamp Id',
    type: 'Stamp type',
    usedStamp: 'Used Stamp',
    description: 'Stamp card list',
    label: 'Stamp Cards',
    emptyLabel:
      'Your restaurant does not have any Stamp. Please contact to Capichi for supporting.',
  },
  voucherHistory: {
    label: 'Voucher History',
    description: "Statistics of the user's voucher usage",
    emptyLabel: 'Currently have no customer use voucher',
  },
  stampHistory: {
    label: 'Stamp history',
    discount: 'Discount',
    emptyAutocomplete: 'Currently this customer does not use any stamp',
    description: "Statistics of the user's stamp usage",
    emptyLabel: 'Currently have no customer use Stamp',
  },
  options: {
    all: 'All',
    collect: 'Collect stamp',
    used: 'Use stamp',
  },
  detailStampHistory: {
    storeName: 'Restaurant name',
    user: 'User',
    quantity: 'Stamp quantity',
    labelChange: 'Account: %{user} has changed Order value:',
    from: 'From',
    label: 'Changed history',
    more: 'Show more',
  },
  signIn: 'Sign In',
  hello: 'Welcome',
  validatePassword: 'Please insert password from 8-25 characters',
  validateField: 'Please insert all fields.',
  validateEmail: 'Please insert correct validate Email.',
  password: 'Password',
  email: 'Email Address',
  view: 'View',
  edit: 'Edit',
  money: 'Moeny',
  emptyData: 'Empty result',
  title: 'Title',
  time: 'Time',
  customer: 'Customer',
  storeName: 'Store name',
  address: 'Address',
  image: 'Image',
  startDate: 'Start date',
  endDate: 'End date',
  action: 'Filter',
  quantity: 'Quantity',

  rowPerPage: 'Rows per page',

  dashboard: {
    sidebar: 'Statistics',
    used_stamp: 'Used stamp',
    gender: 'Statistics for Gender',
    age: 'Statistics for Age',
    number_of_visit: 'Number of visit',
    used_coupon: 'Used coupon',
    no_data: 'Empty data',
  },

  member: {
    title: 'Member of store',
    description_: 'Restaurant have ',
    _description: ' members',
    sidebar: 'Member',
    average_paying: 'Average paying',
    visit_frequency: 'Visit frequency',
    total_point: 'Totol stamp',
    _total: '',
    _point: 'Total stamp',
    total_visit: 'Total visit',
    gender: 'Gender',
    age: 'Age',
    note: 'Note',
    number_stt: 'Order',
    time: 'Time',
    date: 'Date',
    paying: 'Paying money',
    point: 'Point',
    visiting_history: 'Visit history',
    noData: 'Your restaurant does not have any Stamp. Please contact to Capichi for supporting',
    time_day: '%{time} time/%{day} day',
    day_time: '%{day} day/%{time} time',
  },

  male: 'Male',
  female: 'Female',
  other: 'Other',
  unknown: 'Unknown',

  currency: 'VNĐ',

  somethingWrong: 'Something wrong. Please try again.',

  lt20: '<20',
  '20_30': '20-30',
  '30_40': '30-40',
  '40_50': '40-50',
  '50_60': '50-60',
  gt60: '>=60',

  '1': '1',
  '2_5': '1-5',
  '5_10': '5-10',
  gt10: '>=10',

  region: {
    vietnam: 'Vietnamese',
    japan: 'Japanese',
    korean: 'Korean',
  },

  save: 'Save',
  errorAge: 'Please input age range from 13 - 100',

  updateSuccess: 'Update successful!',
  content: 'Content',
  errorNotification: {
    requiredTitle: 'Please input title for Announcement!',
    requiredContent: 'Please input content for Announcement!',
  },
  pushSuccess:
    'Your accouncement has sent successful to Admin. Please wait for responding from Admin to send Announcement to Customer.',
  addNewImage: 'Add image',
  nationality: 'Nationality',
  language: {
    vi: 'Tiếng Việt',
    ja: '日本語',
    ko: '한국',
    en: 'English',
  },
}
