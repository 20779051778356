import { Button, makeStyles } from '@material-ui/core'

import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  buttonfilter: {
    backgroundColor: '#f89a2b',
  },
  buttonCreate: {
    // width: '100%',
    display: 'flex',
    // justifyContent: 'flex-end',
  },
}

const CreateButton = ({ title, onClick }) => {
  const classes = makeStyles(styles)()
  return (
    <div className={classes.buttonCreate}>
      <Button
        variant="contained"
        color="primary"
        className={classes.buttonfilter}
        style={{ width: '100%' }}
        onClick={onClick}
      >
        {title}
      </Button>
    </div>
  )
}

CreateButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
}

export default CreateButton
