import I18n from 'i18n-js'
import { SET_LANGUAGE } from '../actions/actionTypes'
import vi from '../../config/locales/vi'
import ja from '../../config/locales/ja'
import ko from '../../config/locales/ko'
import en from '../../config/locales/en'

const defaultLocale = localStorage.getItem('LOCALE')

const setI18nConfig = localeSelect => {
  if (localeSelect) {
    localStorage.setItem('LOCALE', localeSelect)
  } else {
    localStorage.setItem('LOCALE', 'ja')
  }
  let locale = {}
  // let locale = RNLocalize.findBestAvailableLanguage(['en-US', 'vi-VN'])
  if (localeSelect === 'vi') {
    locale = {
      isRTL: false,
      languageTag: 'vi-VN',
    }
  } else if (localeSelect === 'ko') {
    locale = {
      isRTL: false,
      languageTag: 'ko-KR',
    }
  } else if (localeSelect === 'en') {
    locale = {
      isRTL: false,
      languageTag: 'en-US',
    }
  } else {
    locale = {
      isRTL: false,
      languageTag: 'ja-JP',
    }
  }

  I18n.defaultLocale = 'ja-JP'
  I18n.locale = locale.languageTag

  I18n.fallbacks = true
  I18n.translations = {
    vi,
    ja,
    en,
    ko,
  }
  const I18nConfig = { ...I18n }
  return I18nConfig
}

const initialState = {
  language: setI18nConfig(defaultLocale),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, language: setI18nConfig(action.language) }
    default:
      return state
  }
}
